import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

export interface TwoPieceHeadingProps {
  title: {
    label: string;
    additional?: string;
  };
  url?: string;
  variant?: 'big';
}

const TwoPieceHeading = (props: TwoPieceHeadingProps) => {
  const { title, url, variant } = props;
  const additional = title?.additional;

  const ComponentBody = (
    <div
      className={clsx('font-bold capitalize', {
        'text-2xl text-neutral-5': variant === 'big',
      })}
    >
      {title?.label}{' '}
      {additional && <span className="font-normal">{additional}</span>}
    </div>
  );

  if (url) {
    return (
      <Link href={url} passHref>
        <a className="font-bold">{ComponentBody}</a>
      </Link>
    );
  }

  return ComponentBody;
};

export default TwoPieceHeading;
