import clsx from 'clsx';
import chunk from 'lodash/chunk';
import React from 'react';
import GroupHeading from '../atoms/GroupHeading';
import HeadingIcon from '../atoms/HeadingIcon';
import GroupedTile from '../molecules/GroupedTile';
import { GroupedTilesSectionProps } from '../types';

const GroupedTiles = (
  props: Pick<GroupedTilesSectionProps, 'tiles_groups'>,
) => {
  const { tiles_groups } = props;

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-32">
      {tiles_groups.map((group) => {
        const { heading, tiles } = group;
        const chunkOfTilesCollection = chunk(tiles, 1);

        return chunkOfTilesCollection.map((collection, index) => {
          const isFirstIndex = index === 0;
          const isLastChunk = index === chunkOfTilesCollection.length - 1;
          const isLastChunkOfSingle = isLastChunk && isFirstIndex;
          const isLastChunkOfMultiple = isLastChunk && !isFirstIndex;

          const {
            heading: { icon: headingIcon },
          } = group;

          return (
            <div
              key={`group-${heading.title}-${index}`}
              className={clsx('flex flex-col', {
                'md:col-span-2 lg:col-span-4': collection.length === 4,
                'md:col-span-2 lg:col-span-3': collection.length === 3,
                'md:col-span-2': collection.length > 1,
              })}
            >
              <div className="flex justify-between items-center">
                {isFirstIndex && (
                  <div>
                    <GroupHeading {...heading} />
                  </div>
                )}
                {isLastChunkOfSingle && headingIcon && (
                  <HeadingIcon icon={headingIcon} />
                )}
              </div>

              <div className={clsx('w-full items-center gap-32')}>
                {isLastChunkOfMultiple && headingIcon && (
                  <HeadingIcon icon={headingIcon} />
                )}

                {collection.map((tile, i) => (
                  <GroupedTile
                    {...tile}
                    key={`tile-${tile.title.label}-${i}`}
                  />
                ))}
              </div>
            </div>
          );
        });
      })}
    </div>
  );
};

export default GroupedTiles;
