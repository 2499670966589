import { GroupedTilesHeadingProps } from '../../types';

const GroupHeading = (props: GroupedTilesHeadingProps) => {
  const { title } = props;
  return (
    <div className="flex items-center justify-between py-14">
      <p
        className="uppercase text-xsExtra text-neutral-5 font-extrabold"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </div>
  );
};

export default GroupHeading;
