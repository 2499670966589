import TwoPieceHeading from '@components/views/TwoPieceHeading';
import omit from 'lodash/omit';
import { GroupedTileProps } from '../../types';
import ContainImage from '@components/views/ContainImage';
import Link from 'next/link';
import clsx from 'clsx';

const GroupedTile = (props: GroupedTileProps) => {
  const { description, icon, url } = props;

  const ComponentBody = (
    <div
      className={clsx('w-full group', {
        'cursor-pointer': !!url,
      })}
    >
      <div
        className={clsx(
          'bg-neutral-0 mb-20 aspect-1 flex justify-center items-center w-full ',
          {
            'transition-all duration-150 group-hover:shadow-elevation3': !!url,
          },
        )}
      >
        {icon ? (
          <div
            className={clsx('w-full max-w-[80%]', {
              'transition-all duration-150 scale-95 grayscale group-hover:scale-100 group-hover:grayscale-0':
                !!url,
            })}
          >
            <ContainImage src={icon} />
          </div>
        ) : (
          <div
            className={clsx({
              'transition-all duration-150 scale-95 group-hover:scale-100':
                !!url,
            })}
          >
            <TwoPieceHeading {...omit(props, 'description')} variant="big" />
          </div>
        )}
      </div>

      <TwoPieceHeading {...omit(props, 'description')} />
      {description && (
        <p
          className="text-neutral-5 text-sm mt-8"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </div>
  );

  if (url) {
    return (
      <Link href={url} passHref>
        <a>{ComponentBody}</a>
      </Link>
    );
  }

  return ComponentBody;
};

export default GroupedTile;
