import React from 'react';
import { GroupedTilesHeadingProps } from '../../types';
import Image from 'next/image';

const HeadingIcon = ({ icon }: { icon: GroupedTilesHeadingProps['icon'] }) => {
  if (!icon) return <></>;

  return (
    <div className="py-14 flex justify-end items-center">
      <Image src={icon} width={20} height={20} alt="ikona grupy" />
    </div>
  );
};

export default HeadingIcon;
