import Container from '@components/containers/Container';
import CrossSellingPattern from '../../../assets/images/cross-selling-pattern.svg';
import GroupedTiles from './organisms';
import Heading from '../Heading';
import React from 'react';
import clsx from 'clsx';
import { GroupedTilesSectionProps } from './types';
import { usePageColors } from 'src/hooks/usePageColors';

const GroupedTilesSection = (
  props: GroupedTilesSectionProps & {
    hasDecoration?: boolean;
  },
) => {
  const { header, tiles_groups, hasDecoration = true, bgColor } = props;
  const sectionHeading = header?.heading;
  const description = header?.description;
  const colors = usePageColors();

  return (
    <section
      className={clsx('py-40 md:py-64 lg:py-96 overflow-hidden', {
        'bg-neutral-1': !bgColor || bgColor === 'default',
      })}
      style={{
        ...(colors?.washed && {
          backgroundColor: colors.washed,
        }),
      }}
    >
      <Container className="relative">
        {hasDecoration && (
          <div className="absolute top-40 md:top-64 right-0 translate-x-1/2 lg:translate-x-2/3 z-10">
            <CrossSellingPattern />
          </div>
        )}
        <div className="grid md:grid-cols-2 gap-32 mb-64">
          <div>
            {sectionHeading && (
              <div className="text-neutral-9 font-extrabold text-4xl mb-16">
                <Heading {...sectionHeading} />
              </div>
            )}
            {description && (
              <p
                className="text-neutral-9"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </div>
        </div>
        <GroupedTiles tiles_groups={tiles_groups} />
      </Container>
    </section>
  );
};

export default GroupedTilesSection;
