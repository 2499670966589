import GroupedTilesSection from '@components/views/GroupedTilesSection';
import { HeadingProps } from '@components/views/Heading';
import { ProductTilesGroupedByCategoriesProps } from '../ProductTilesGroupedByCategories';

export interface ProductTilesGroupedByCategoriesSectionProps {
  tiles: ProductTilesGroupedByCategoriesProps;
  heading: HeadingProps;
  description: string;
  bgColor?: 'default' | 'washed';
}

const ProductTilesGroupedByCategoriesSection = (
  props: ProductTilesGroupedByCategoriesSectionProps,
) => {
  const { tiles, heading, description, bgColor } = props;

  return (
    <>
      <GroupedTilesSection
        header={{
          heading,
          description,
        }}
        tiles_groups={tiles.categories.map((item) => {
          const { posts, category } = item;

          return {
            heading: {
              title: category.name,
              icon: category.icon || '',
            },
            tiles: posts.map(({ product, description, custom_title }) => {
              return {
                title: {
                  label: custom_title?.label || product.post_name,
                  additional: custom_title?.additional || '',
                },
                description: description,
                url: product.url,
                icon: product.thumbnail,
              };
            }),
          };
        })}
        bgColor={bgColor}
      />
    </>
  );
};

export default ProductTilesGroupedByCategoriesSection;
